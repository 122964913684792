"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeDownloadSource = exports.activeDownload = void 0;
function isMacOS() {
    return navigator.appVersion.indexOf('Mac') > -1;
}
function activeDownload() {
    const downloadContainer = document.querySelector('.download.app');
    if (isMacOS()) {
        const win32 = downloadContainer.children[0];
        const mac = downloadContainer.children[1];
        win32.style.order = '1';
        win32.classList.remove('primary-download');
        win32.classList.add('secondary-download');
        mac.style.order = '0';
        mac.classList.remove('secondary-download');
        mac.classList.add('primary-download');
    }
    //   if(isMacOS()) {
    //   macosDownloadContainer.style.display = 'flex'
    // } else {
    //   win32DownloadContainer.style.display = 'flex'
    // }
}
exports.activeDownload = activeDownload;
function changeDownloadSource() {
    // get the url params from the current url and append them to the download links
    const params = new URLSearchParams(window.location.search);
    const downloadLinks = document.querySelectorAll('.source-link');
    downloadLinks.forEach(link => {
        const url = link.href;
        const newUrl = new URL(url);
        params.forEach((value, key) => {
            newUrl.searchParams.set(key, value);
        });
        link.href = newUrl.toString();
    });
}
exports.changeDownloadSource = changeDownloadSource;
