"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// css
require("./assets/css/files.css");
require("./assets/css/frame.css");
require("./assets/css/scene.css");
const download_1 = require("./download");
// js
const files_1 = require("./files");
const nav_1 = require("./nav");
main();
function main() {
    (0, download_1.activeDownload)();
    (0, download_1.changeDownloadSource)();
    // get url query ia=
    const url = new URL(location.href);
    const ia = url.searchParams.get('ia') || '';
    let activeIcon = 'add-download';
    if (['apps', 'browser', 'cursor', 'add-download', 'repair', 'setting', 'theme'].includes(ia)) {
        activeIcon = ia;
    }
    (0, nav_1.initNav)({
        activeIcon
    });
    // createWave()
    (0, files_1.initFiles)();
}
