"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounceAdv = void 0;
function debounceAdv(fn, delay = 100) {
    let timeoutId = null;
    return function (...args) {
        if (!timeoutId) {
            fn.apply(this, args);
        }
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            clearTimeout(timeoutId);
            timeoutId = null;
        }, delay);
    };
}
exports.debounceAdv = debounceAdv;
